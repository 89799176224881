import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  private motivationOverallProgressBar = new Subject();
  overallProgressBarValue = this.motivationOverallProgressBar.asObservable();
  private isMotivationQuestionnaireView: Subject<boolean> = new Subject();
  motivationQuestionnaireView: Observable<boolean> = this.isMotivationQuestionnaireView.asObservable();

  constructor() {}

  changeoverallProgressBarValue(value: number) {
    this.motivationOverallProgressBar.next(value);
  }

  isMotivationQuestionnaire(value: boolean) {
    this.isMotivationQuestionnaireView.next(value);
  }

}
